.job-page {
  display: flex;
  height: calc(100% - 6.8rem);
}

.job-page > .left-panel {
  border-right: 1px solid #DDDDDD;
  width: 44rem;
}

.job-page > .right-panel {
  width: calc(100% - 44rem);
  overflow: auto;
}

@media (max-width: 991px) {
  .job-page {
    flex-wrap: wrap;
  }

  .job-page > .left-panel {
    width: 100%;
  }

  .job-page > .right-panel {
    width: 100%;
  }
}