.document-review {
  height: 100%;
  overflow: auto;
  margin: 0;
}

.document-review > .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  padding: 1.6rem 3.2rem;
  border-bottom: 1px solid var(--neutral-gray);
  line-height: 1.3rem;
  min-height: 11.5rem;
}

.document-review > .header > .go-back {
  font-size: 1.3rem;
  font-weight: 500;
}

.document-review > .header > .content > .details {
  width: calc(100% - 58rem);
}

.document-review > .header > .content > .details > .title {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 800;
  margin: 0.8rem 0;
  cursor: default;
}

.document-review > .content {
  display: flex;
  height: calc(100% - 11.5rem);
  overflow: hidden;
}

.document-review > .content > .left-panel {
  border-right: 1px solid var(--neutral-gray);
  width: 44rem;
  min-width: 44rem;
  max-width: calc(100% - 64rem);
}

.document-review > .content > .right-panel {
  width: calc(100% - 45.5rem);
  margin-left: 1.5rem;
}

@media (max-width: 1080px) {
  .document-review > .header {
    position: relative;
  }
  
  .document-review > .content {
    flex-wrap: wrap;
    height: auto;
  }

  .document-review > .content >.left-panel {
    width: 100%;
    min-width: 100%;
  }

  .document-review > .content > .right-panel {
    width: 100%;
    margin-left: 0;
  }

  .document-review > .header > .content {
    flex-wrap: wrap;
  }

  .document-review > .header > .content > .details {
    width: 100%;
  }

  .document-review > .header > .content > * {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}