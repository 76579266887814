.control {
  cursor: pointer;
}

.content {
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--text-primary);
  background-color: #FFFFFF;
  border: 1px solid var(--light-gray);
  border-radius: 0.8rem;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
  max-width: 44rem;
}