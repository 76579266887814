.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 0.8rem;
}

.modal.fade {
  z-index: -1;
  opacity: 0;
}

.modal.fade .modal-dialog {
  transition: 0.3s all;
}

.modal.show .modal-dialog {
  transform: translateY(50px);
  transition: 0.3s all;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;
  margin-top: 10%;
  pointer-events: none;
  max-width: 600px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.8rem;
  outline: 0;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header h4 {
  line-height: 2.6rem
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.4rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.6rem 2.4rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
  z-index: -1;
  transition: 0.3s all;
}

.modal-backdrop.show {
  transition: 0.3s all;
  opacity: .5;
}
