.dashboard-page {
  padding: 5rem 15rem;
}

.dashboard-page > .header .search {
  width: 40rem;
}

.dashboard-page > .section {
  margin-top: 4.8rem;
}

.dashboard-page > .section > .subtitle {
  font-weight: 700;
}

.widget-card {
  border: 1px solid var(--light-gray);
  border-radius: 0.6rem;
  padding: 2rem 2.4rem;
}

.widget-card.highlighted {
  border-color: var(--light-blue);
  background-color: var(--light-blue);
}

.widget-card.highlighted>.action {
  color: var(--blue-hyperlink);
}

.widget-card > .heading {
  font-size: 1.4rem;
  font-weight: 700;
}

.widget-card > .action > .title {
  font-size: 2.4rem;
  font-weight: 500;
}

.widget-card > .timestamp {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-secondary);
}