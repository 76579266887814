.badge {
  font-size: 1.2rem;
  font-weight: 510;
  padding: 0.2rem 0.8rem;
  color: var(--text-primary);
  border-radius: 0.3rem;
  white-space: nowrap;
}

.badge.badge-default {
  background-color: var(--neutral-gray);
}

.badge.badge-bland {
  background-color: var(--bland-gray);
}

.badge.badge-danger-light {
  background-color: var(--danger-light);
}

.badge.badge-warning {
  background-color: var(--warning);
}

.badge.badge-info {
  background-color: var(--sky-blue);
}

.badge.badge-success {
  background-color: var(--secondary);
  border: 1px solid var(--success);
}

.badge.badge-danger {
  background-color: var(--danger);
  color: var(--secondary);
}