.notification-item {
  width: 32rem;
}

.notification-item > .content > .title {
  font-weight: 500;
  color: var(--primary);
}

.notification-item > .content > .timestamp {
  color: var(--text-secondary);
}

.notification-item > .dot {
  height: 1rem;
  width: 1rem;
  background-color: #FF4E4E;
  border-radius: 50%;
  margin-right: -2px;
}