.resizablePanel {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splitter {
  position: absolute;
  top: 0;
  right: -1.6rem;
  width: 1.6rem;
  height: 100%;
  cursor: ew-resize;
  border-left: 1px solid;
  border-right: 1px solid;
  background-color: var(--secondary);
  border-color: var(--neutral-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.splitter:hover,
.splitter:active {
  background-color: var(--light-blue);
  color: var(--blue-hyperlink);
}