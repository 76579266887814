.job-details {
  padding: 3.2rem 4rem 0;
  height: 100%;
}

.job-details > .content {
  height: calc(100% - 7rem);
}

.job-details th:nth-child(1) {
  width: 43%;
}

.job-details th:nth-child(2) {
  width: 10%;
}

.job-details th:nth-child(3) {
  width: 10%;
}

.job-details th:nth-child(4) {
  width: 20%;
}

.job-details th:nth-child(5) {
  width: 12%;
}

.job-details th:nth-child(6) {
  width: 5%;
}