@font-face {
  font-family: "SF Pro";
  font-weight: 400;
  src: url("./assets/fonts/Inter-Regular.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

#root {
  margin: 0 auto;
}

/************* HEADER *************/
header {
  display: flex;
  height: 6.8rem;
  background-color: var(--deep-blue);
}

header > .logo {
  margin: auto 2.8rem;
}

header > .logo > img {
  height: 22px;
}

header > .profile {
  position: relative;
  color: var(--secondary);
  margin: auto 1.6rem auto auto;
  display: flex;
  align-items: center;
}
/************* HEADER *************/

.react-select-control input:not([inputmode="none"]) {
  height: 2.4rem;
}

.react-select-control svg {
  height: 1.6rem;
  fill: #666666;
}

.react-select-clear {
  padding: 0.8rem 2.4rem;
  font-size: 1.2rem;
  text-align: right;
  color: var(--primary);
  cursor: pointer;
}

.react-select-clear:hover {
  color: var(--primary-hover);
}

.react-select-item {
  padding: 0 1.2rem;
}

.react-select-item > label {
  font-size: 1.3rem;
  font-weight: 400;
}