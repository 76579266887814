.job-card {
  padding: 2rem;
  border: 1px solid var(--bright-gray);
  border-radius: 0.8rem;
  font-weight: 400;
  line-height: 1.56rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
}

.job-card.selected {
  border-color: #0D5BC4;
}

.job-card.selected,
.job-card:hover {
  background-color: var(--light-blue);
}

.job-card > .header > .download {
  margin-left: auto;
  letter-spacing: 0;
  height: auto;
}

.job-card > .file-count {
  font-size: 1.3rem;
}

.job-card>.header,
.job-card > .footer {
  display: flex;
  align-items: center;
}

.job-card > .header {
  margin-bottom: 1.6rem;
}

.job-card > .footer {
  margin-top: 1.2rem;
  min-height: 3.2rem;
}

.job-card >.footer > .timestamp {
  font-size: 1.1rem;
  line-height: 1.32rem;
  color: #767676;
  margin-right: auto;
}

.job-card > .header .job-action {
  padding: 0.8rem;
  margin-right: -0.8rem;
}

.job-card > .footer .job-action {
  padding: 0 0.8rem;
  margin-right: -0.8rem;
  margin-left: -0.8rem;
}