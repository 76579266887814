.nav-menu {
  padding: 0 1.2rem;
  display: flex;
  font-weight: 590;
  font-size: 1.4rem;
}

.nav-menu > .nav-item {
  display: flex;
  align-items: center;
  margin: 0 3.2rem;
  height: 100%;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  color: var(--secondary);
}

.nav-menu > .nav-item.active {
  border-bottom-color: #0587FF;
}
