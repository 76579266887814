.notifications { 
  position: relative;
}

.notifications .dot {
  position: absolute;
  height: 0.7rem;
  width: 0.7rem;
  background-color: #FF4E4E;
  border-radius: 50%;
  right: 1.0875rem;
  top: 1.2rem;
}