.dropdown {
  z-index: 3;
}

.dropdown > .dropdown-menu {
  padding: 1.6rem 0;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  background-color: #FFFFFF;
  border: 1px solid var(--light-gray);
  border-radius: 0.2rem 0.2rem 0.8rem 0.8rem;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
  min-width: 24rem;
  color: var(--text-primary);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.dropdown > .dropdown-menu > .dropdown-item {
  cursor: pointer;
  padding: 0.8rem 2.4rem;
}

.dropdown .dropdown-menu > .dropdown-item:hover,
.dropdown .dropdown-menu > .dropdown-item:focus {
  background-color: var(--light-blue);
}
