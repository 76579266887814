.document-table > .filters {
  margin: 3.2rem 0 2.4rem 0;
}

.document-table th:nth-child(1),
.document-table th:nth-child(3),
.document-table th:nth-child(4),
.document-table th:nth-child(6) {
  width: 10%;
}

.document-table th:nth-child(2) {
  width: 42%;
}

.document-table th:nth-child(5) {
  width: 15%;
}

.document-table th:nth-child(7) {
  width: 3%;
}

.document-table th {
  cursor: pointer;
}

.document-table th .sort-indicator {
  display: flex;
  flex-direction: column;
}

.document-table th .sort-indicator > svg {
  height: 0.8rem;
}

.document-table th .sort-indicator>svg:first-child {
  margin-bottom: -0.1rem;
}

.document-table th .sort-indicator>svg:last-child {
  margin-top: -0.1rem;
}

.document-table .timeframe-filter {
  width: 15rem;
}

.document-table .status-filter {
  width: 12rem;
}