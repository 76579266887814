.file-repository {
    padding: 3.2rem 4rem;
    overflow-y: auto;
    height: calc(100% - 6.8rem);
}

.file-repository > .header > .title {
    font-size: 3rem;
    font-weight: 510;
    line-height: 3rem;
}

.file-repository > .content {
    height: calc(100% - 3rem);
}