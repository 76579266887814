ul.pagination {
  margin: 0;
  list-style: none;
  user-select: none;
}

ul.pagination > li {
  margin: 0;
  display: inline-block;
  border-radius: 50%;
  padding: 1rem;
  height: 3.5rem;
  width: 3.5rem;
  margin: 0 0.2rem;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 1.6rem;
}

ul.pagination > li:hover:not(.separator),
ul.pagination > li.current {
  color: var(--primary);
  background-color: var(--light-blue);
}

ul.pagination > li.separator {
  padding: 0;
  width: auto;
  height: auto;
}

ul.pagination > li[disabled] {
  color: var(--text-primary) !important;
  background-color: transparent !important;
  opacity: 0.5;
}

ul.pagination > li.separator,
ul.pagination > li[disabled] {
  cursor: default;
}