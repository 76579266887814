:root {
  /******** Core Colors ********/

  --primary: #0D5BC4;
  --secondary: #FFFFFF;
  --secondary-hover: #EFF9FC;
  --primary-hover: #174294;
  --danger: #CB2805;
  --danger-light: #FCE1DD;
  --warning: #F4E99A;
  --success: #63BB33;
  --deep-blue: #05354C;
  --deep-pink: #FF006B;
  --sky-blue: #9ACFFF;
  --light-blue: #F2F7F9;
  --neutral-gray: #DDDDDD;
  --light-gray: #CACACA;
  --bright-gray: #E8E8E8;
  --gray-med: #AAAAAA;
  --bland-gray: #F6F6F6;
  --blue-hyperlink: #215A8E;
  --text-primary: #222222;
  --text-secondary: #767676;
  --dark-transparent: #000000ce;
  --dark-red: #630400;

  /******** Tostify Color Scheme ********/

  --toastify-color-success: var(--success);
  --toastify-color-warning: var(--warning);
  --toastify-color-error: var(--danger);
  --toastify-toast-width: 400px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary);
  font-weight: 500;
}
strong {
  font-weight: 510;
  font-size: 1.3rem;
}

a {
  color: var(--primary);
}

label {
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
  font-weight: 590;
  color: #000000;
}

input {
  background: #FFFFFF;
  border: 1px solid var(--light-gray);
}

fieldset,
input,
select,
textarea {
  margin-bottom: 1.6rem;
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  height: 4.4rem;
  padding: 1.4rem;
  font-size: 1.4rem;
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--primary)!important;
}

input.invalid-field,
select.invalid-field,
textarea.invalid-field {
  border-color: var(--danger)!important;
}

input + .invalid-feedback,
select +.invalid-feedback,
textarea +.invalid-feedback {
  padding: 0.8rem 0;
  color: var(--danger);
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: -1.6rem;
  display: block;
}

select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='10' height='6' viewBox='0 0 448 225' xml:space='preserve'%3E%3Cdesc%3ECreated with Fabric.js 3.6.3%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg transform='matrix(1 0 0 1 224.46 112.51)' %3E%3Cpath style='stroke: none%3B stroke-width: 1%3B stroke-dasharray: none%3B stroke-linecap: butt%3B stroke-dashoffset: 0%3B stroke-linejoin: miter%3B stroke-miterlimit: 4%3B fill: rgb(102 102 102)%3B fill-rule: nonzero%3B opacity: 1%3B' transform=' translate(-223.96  -287.1)' d='M 4.251 181.1 C 7.392 177.7 11.69 175.1 16 175.1 c 3.891 0 7.781 1.406 10.86 4.25 l 197.1 181.1 l 197.1 -181.1 c 6.5 -6 16.64 -5.625 22.61 0.9062 c 6 6.5 5.594 16.59 -0.8906 22.59 l -208 192 c -6.156 5.688 -15.56 5.688 -21.72 0 l -208 -192 C -1.343 197.7 -1.749 187.6 4.251 181.1 z' stroke-linecap='round' /%3E%3C/g%3E%3C/svg%3E");  padding-right: 4rem;
  background-position-x: calc(100% - 1.5rem);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: 3.6rem;
}

::-webkit-file-upload-button {
  padding: 1rem 4rem;
  border-radius: 2rem;
  border: 1px solid var(--light-gray);
  margin-right: 2.4rem;
  font-weight: 510;
  font-size: 1.4rem;
  line-height: 1.4rem;
  cursor: pointer;
}

input[type="file"] {
  border: none;
  width: 100%;
}

.form-icon-group {
  position: relative;
}

.form-icon-group > input {
  padding-left: 4.8rem;
  height: 3.6rem;
}

.form-icon-group.icon-lg > input {
  height: 4rem;
}

.form-icon-group > svg {
  position: absolute;
  left: 1.6rem;
  top: 1.4rem;
  color: var(--text-secondary);
}

.form-icon-group.icon-lg > svg {
  top: 1.2rem
}

.form-icon-group.form-sm > input {
  padding-left: 3.6rem;
}

.form-icon-group.form-sm > svg {
  left: 1rem;
  top: 1rem;
  height: 1.6rem;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check > input[type="checkbox"] {
  accent-color: var(--primary);
  height: 1.6rem;
  width: 1.6rem;
  margin: 0;
}

.form-check > label {
  margin: 0 1.2rem;
}

@media (min-width: 40rem) {
  .row {
    width: 100%;
  }
}

.card {
  background: var(--secondary);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  padding: 16px;
}

.card>.card-body,
.card>.card-header,
.card>.card-footer {
  padding: 16px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: var(--success);
}

.text-danger {
  color: var(--danger);
}

.text-neutral {
  color: var(--neutral-gray);
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-white {
  color: var(--secondary);
}

.text-gray-med {
  color: var(--gray-med);
}

.text-info {
  color: var(--primary);
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

.w-0 {
  width: 0%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

/********** BUTTONS ***********/

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  height: 3.6rem;
  font-size: 1.3rem;
  line-height: 100%;
  font-weight: 700;
  text-transform: none;
  background-color: var(--primary);
  border-color: var(--primary);
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0;
  letter-spacing: unset;
}

.button:focus,
.button:hover,
button:focus,
button:hover,
input[type='button']:focus,
input[type='button']:hover,
input[type='reset']:focus,
input[type='reset']:hover,
input[type='submit']:focus,
input[type='submit']:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.button.button-outline:focus,
.button.button-outline:hover,
button.button-outline:focus,
button.button-outline:hover,
input[type='button'].button-outline:focus,
input[type='button'].button-outline:hover,
input[type='reset'].button-outline:focus,
input[type='reset'].button-outline:hover,
input[type='submit'].button-outline:focus,
input[type='submit'].button-outline:hover,
.button.button-light:hover,
.button.button-light:focus {
  background-color: var(--secondary-hover);
  border-color: var(--primary-hover);
  color: var(--primary-hover);
}

.button.button-clear:hover,
.button.button-clear:focus,
.button.button-light:hover,
.button.button-light:focus,
.button.button-link:hover,
.button.button-link:focus {
  background-color: #D3E4EC;
  color: var(--primary-hover);
}

.button.button-link {
  border: unset;
}

.button[disabled],
input[type="submit"][disabled] {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.button.button-outline[disabled] {
  background-color: var(--secondary) !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.button.button-clear[disabled],
.button.button-light[disabled] {
  background-color: var(--secondary) !important;
  color: var(--text-secondary);
}

.button.button-clear[disabled] {
  border-color: var(--secondary) !important;
}

.button.button-light[disabled] {
  border-color: var(--neutral-gray)!important;
}

.button.button-link[disabled] {
  background-color: transparent!important;
  color: var(--text-secondary);
}

a.button {
  line-height: 3.2rem;
}

a:hover,
a:focus {
  color: var(--primary-hover);
}

.button-large,
input[type='submit'].button-large {
  height: 4.7rem;
  font-size: 1.5rem;
}

.button.button-outline,
button.button-outline,
button.button-link,
input[type='button'].button-outline,
input[type='reset'].button-outline,
input[type='submit'].button-outline {
  color: var(--primary);
}

.button.button-clear {
  color: var(--text-secondary)
}

.button.button-clear:hover {
  color: var(--primary)
}

.button.button-outline {
  border-width: 0.2rem;
}

.button.button-light {
  background: transparent;
  color: var(--text-primary);
  border-color: var(--neutral-gray);
}

.button.button-header,
.button.button-link {
  background: transparent;
  border-color: transparent;
}

.button.button-header {
  color: var(--secondary);
  font-weight: 400;
  font-size: 1.2rem;
  padding: 1.2rem;
}

.button.button-header:hover,
.button.button-header:focus {
  background-color: #164294;
}

.button-icon {
  padding: 1rem;
  height: 3.5rem;
  width: 3.5rem;
}

.button-icon.button-sm {
  padding: 1rem;
  height: 3.5rem;
  width: 3.5rem;
}

.button-small {
  height: 3.2rem;
  line-height: 3.2rem;
  padding: 0 1.5rem;
}


/********** BUTTONS ***********/

/********** MARGINS ***********/

.m-auto {
  margin: auto!important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0;
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-1 {
  margin-right: 0.8rem;
}

.ml-1 {
  margin-left: 0.8rem;
}

.mt-1 {
  margin-top: 0.8rem;
}

.mb-1 {
  margin-bottom: 0.8rem;
}

.mr-2 {
  margin-right: 1.2rem;
}

.ml-2 {
  margin-left: 1.2rem;
}

.mt-2 {
  margin-top: 1.2rem;
}

.mb-2 {
  margin-bottom: 1.2rem;
}

.mr-3 {
  margin-right: 1.6rem;
}

.ml-3 {
  margin-left: 1.6rem;
}

.mt-3 {
  margin-top: 1.6rem;
}

.mb-3 {
  margin-bottom: 1.6rem;
}

.mr-4 {
  margin-right: 2.4rem;
}

.ml-4 {
  margin-left: 2.4rem;
}

.mt-4 {
  margin-top: 2.4rem;
}

.mb-4 {
  margin-bottom: 2.4rem;
}

.mr-5 {
  margin-right: 3.2rem;
}

.ml-5 {
  margin-left: 3.2rem;
}

.mt-5 {
  margin-top: 3.2rem;
}

.mb-5 {
  margin-bottom: 3.2rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-1 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.my-1 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.mx-2 {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.my-2 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.mx-3 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.my-3 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.mx-4 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}

.my-4 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.mx-5 {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}

.my-5 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

/********** MARGINS ***********/

/********** PADDINGS ***********/

.pl-auto {
  padding-left: auto;
}

.pr-auto {
  padding-right: auto;
}

.p-0 {
  padding: 0;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.p-1 {
  padding: 0.8rem;
}

.pr-1 {
  padding-right: 0.8rem;
}

.pl-1 {
  padding-left: 0.8rem;
}

.pt-1 {
  padding-top: 0.8rem;
}

.pb-1 {
  padding-bottom: 0.8rem;
}

.p-2 {
  padding: 1.2rem;
}

.pr-2 {
  padding-right: 1.2rem;
}

.pl-2 {
  padding-left: 1.2rem;
}

.pt-2 {
  padding-top: 1.2rem;
}

.pb-2 {
  padding-bottom: 1.2rem;
}

.p-3 {
  padding: 1.6rem;
}

.pr-3 {
  padding-right: 1.6rem;
}

.pl-3 {
  padding-left: 1.6rem;
}

.pt-3 {
  padding-top: 1.6rem;
}

.pb-3 {
  padding-bottom: 1.6rem;
}

.p-4 {
  padding: 2.4rem;
}

.pr-4 {
  padding-right: 2.4rem;
}

.pl-4 {
  padding-left: 2.4rem;
}

.pt-4 {
  padding-top: 2.4rem;
}

.pb-4 {
  padding-bottom: 2.4rem;
}

.p-5 {
  padding: 3.2rem;
}

.pr-5 {
  padding-right: 3.2rem;
}

.pl-5 {
  padding-left: 3.2rem;
}

.pt-5 {
  padding-top: 3.2rem;
}

.pb-5 {
  padding-bottom: 3.2rem;
}

/********** PADDINGS ***********/

/********** TABLES ***********/

table td,
table th {
  border-color: #DDDDDD;
  font-size: 1.3rem;
}

table td {
  font-weight: 400;
}

table th {
  font-weight: 510;
}

table > tfoot td {
  border-bottom: unset;
}

/********** TABLES ***********/

/********** TYPOGRAPHY ***********/

.font-13 {
  font-size: 1.3rem;
}
 
.fw-400 {
  font-weight: 400;
}

.text-black {
  color: var(--text-primary)!important;
}

/********** TYPOGRAPHY ***********/

/********** ALERT BOX ***********/

.alert {
  text-align: center;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin-bottom: 1.6rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.alert.alert-danger {
  background-color: var(--danger-light);
  color: #7E1600;
}

/********** ALERT BOX ***********/

/********** SWEET ALERT **********/

.swal2-popup {
  font-size: 1.6rem !important;
}

body.swal2-height-aut {
  height: 100%!important;
}

.swal2-container .swal2-confirm,
.swal2-container .swal2-cancel {
  box-shadow: unset !important;
  height: 3.6rem;
  font-size: 1.3rem !important;
  line-height: 100%;
  font-weight: 700;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.swal2-container .swal2-cancel {
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
}

.swal2-container .swal2-cancel:hover {
  background-image: unset !important;
  background-color: var(--secondary-hover) !important;
}

.swal2-timer-progress-bar {
  background: var(--sky-blue)!important;
}

.swal2-title {
  font-size: 1.6em!important;
  color: var(--text-primary)!important;
}

.swal2-close {
  font-size: 2em!important;
  box-shadow: unset!important;
  outline: unset!important;
}

.swal2-close:hover,
.swal2-cancel:focus {
  color: var(--primary)!important;
}

/********** SWEET ALERT **********/


.Toastify__close-button {
  background-color: transparent!important;
  margin: auto;
}

.border-0 {
  border: unset;
}