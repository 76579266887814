.nav-tabs {
  font-size: 1.3rem;
  font-weight: 400;
  border-bottom: 1px solid var(--neutral-gray);
  list-style: none;
}

.nav-tabs > .nav-tab {
  margin: 0 3.2rem -0.1rem 3.2rem;
  padding: 1.2rem 0;
  display: inline-block;
  cursor: pointer;
}

.nav-tabs > .nav-tab.active {
  border-bottom: 2px solid #045981;
}

.nav-tabs > .nav-tab:first-child {
  margin-left: 0;
}

.nav-tabs > .nav-tab:last-child {
  margin-right: 0;
}