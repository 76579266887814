.job-list {
  position: relative;
  height: calc(100vh - 6.8rem);
  overflow-x: visible;
}

.job-list > .title {
  display: flex;
  height: 6.4rem;
  border-bottom: 1px solid #DDDDDD;
  align-items: center;
  padding: 1.6rem 2.4rem;
}

.job-list > .actions {
  display: flex;
  position: absolute;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0 1.6rem;
  padding: 1.6rem 0.8rem 1.2rem 0.8rem;
  background-color: white;
  z-index: 2;
}

.job-list > .content {
  height: calc(100% - 6.4rem);
  overflow-y: auto;
  padding: 6.5rem 2.4rem 3.2rem;
}

.job-list > .content > .no-results {
  font-size: 1.4rem;
  font-weight: 500;
}


@media (max-width: 991px) {
  .job-list {
    height: 44rem;
  }

  .job-list > .actions {
    margin-right: 1.6rem!important;
  }
}