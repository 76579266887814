.toggle-switch-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2.4rem;
  min-width: 4rem;
  min-height: 2.4rem;
  margin: 0 0.8rem;
}

.toggle-switch > .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 2.4rem;
}

.toggle-switch > .slider:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 2rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  transition: .4s;
  transition: .4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 50%;
}

.toggle-switch > .slider:hover {
  box-shadow: 0 0 4px var(--primary);
}

.toggle-switch > input {
  display: none;
}

.toggle-switch > input:checked + .slider {
  background-color: var(--primary);
}

.toggle-switch > input[disabled] + .slider {
  opacity: .5;
  cursor: not-allowed;
}

.toggle-switch > input:checked + .slider:before {
  -webkit-transform: translateX(1.6rem);
  -ms-transform: translateX(1.6rem);
  transform: translateX(1.6rem);
}