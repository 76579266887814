.page-not-found {
  height: 100%;
  text-align: center;
  background-color: var(--deep-blue);
}

.page-not-found,
.page-not-found > .message {
  color: var(--secondary);
}

.page-not-found > .code {
  font-size: 25rem;
  font-weight: 500;
}